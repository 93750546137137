/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});


// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const awsmobile_local = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:629ca6e2-f5ba-4144-af57-43e1682106ba",
    
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_xUJST8iOF",
    "aws_user_pools_web_client_id": "1ntvtup4tlp3gi6ei2rcvgvhit",
    "oauth": {
        "domain": "user.dna-morpheus.dsm.app",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fkj7w5rmurgljbxtfcnjkfl5ge.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:629ca6e2-f5ba-4144-af57-43e1682106ba",
    
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_xUJST8iOF",
    "aws_user_pools_web_client_id": "1ntvtup4tlp3gi6ei2rcvgvhit",
    "oauth": {
        "domain": "user.dna-morpheus.dsm.app",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://react-template.dna-morpheus.dsm.app/",
        "redirectSignOut": "https://react-template.dna-morpheus.dsm.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fkj7w5rmurgljbxtfcnjkfl5ge.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;